<template>
  <div class="line-graph">
    <h2 v-if="title" class="title title--h2 title--graph-statistics">{{ title }}</h2>
    <apexchart
      class="line-graph__graph"
      ref="apexchart"
      type="line"
      :options="chartOptions"
      :series="series"
      :height="height"
    ></apexchart>
  </div>
</template>

<script>
  export default {
    name: 'LineGraph',
    props: {
      countArray: {
        type: Array,
        default: () => [],
      },
      labelsArray: {
        type: Array,
        default: () => [],
      },
      title: {
        type: String,
        default: '',
      },
      height: {
        type: [String, Number],
        default: '',
      },
    },
    data() {
      return {
        series: {},
        chartOptions: {},
      };
    },

    created() {
      this.chartOptions = {
        chart: {
          type: 'line',
          dropShadow: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350,
            },
          },
          zoom: {
            enabled: false,
          },
        },
        labels: this.labelsArray,
        // линии на заднем фоне
        grid: {
          borderColor: '#E7EFF4',
        },
        // маркеры на графике
        markers: {
          size: 8,
          hover: {
            size: 8,
            sizeOffset: 3,
          },
          shape: 'circle',
        },
        // нижние точки - маркеры у подписи
        legend: {
          markers: {
            width: 12,
            height: 12,
          },
          itemMargin: {
            horizontal: 32,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
          onItemHover: {
            highlightDataSeries: false,
          },
          horizontalAlign: 'left',
          fontSize: '15px',
          fontFamily: 'Fira Sans',
        },
        // ось x
        xaxis: {
          labels: {
            style: {
              colors: '#989898',
              fontSize: '15px',
              fontFamily: 'Fira Sans',
              letterSpacing: '-0.3px',
            },
          },
        },
        // ось y
        yaxis: {
          labels: {
            style: {
              colors: '#989898',
              fontSize: '15px',
              fontFamily: 'Fira Sans',
              letterSpacing: '-0.3px',
            },
          },
          max: (max) => {
            return max + 1;
          },
          min: 0,
        },
        noData: {
          text: 'Данных нет',
        },
      };

      this.series = this.countArray;
    },

    watch: {
      countArray(val) {
        this.$refs.apexchart.updateSeries(val);
      },
      labelsArray(val) {
        this.$refs.apexchart.updateOptions({
          labels: val,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';

  .line-graph {
    border-radius: 4px;
    background: $light-grey;
    padding: 40px 60px;

    @media (max-width: $tablet) {
      padding: 40px;
    }

    @media (max-width: $laptop) {
      padding: 40px 16px;
      overflow: auto;

      &__graph {
        min-width: 600px;
      }
    }
  }
</style>
