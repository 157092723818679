<template>
  <div class="verticalBarChart" ref="verticalBarChart">
    <BarChart :height="500" :chart-data="data" :options="options" css-classes="verticalBarChart__chart" />
    <span v-if="noData" class="verticalBarChart__no-data">{{ noDataText }}</span>
  </div>
</template>

<script>
  import { defineComponent, ref } from 'vue';
  import { BarChart } from 'vue-chart-3';
  import { Chart, registerables, Tooltip } from 'chart.js';
  Chart.register(...registerables, Tooltip);
  export default defineComponent({
    name: 'VerticalBarChart',
    components: {
      BarChart,
    },

    props: {
      labels: {
        // Категории баров
        type: Array,
        default: () => [],
      },

      datasets: {
        type: Array,
        default: () => [],
      },

      title: {
        // Заголовок по оси Y
        type: String,
        default: '',
      },
      tooltip: {
        // Тип тултипа. default - тултип из коробки chartjs, custom - тултип кастомный дающий возможность вносить изменения в виде html, none - без тултипа
        type: String,
        default: 'none',
        validator(value) {
          return ['default', 'custom', 'none'].includes(value);
        },
      },
      pointSize: {
        type: [Number, String],
        default: 12,
      },
      noData: {
        type: Boolean,
        default: false,
      },
      noDataText: {
        type: String,
        default: 'Данных нет',
      },
      // Шаг оси Y
      stepSizeY: {
        type: Number,
        default: 0.5,
      },
    },

    computed: {
      data() {
        return {
          labels: this.labels,
          datasets: this.datasets,
        };
      },
    },

    setup(props) {
      const verticalBarChart = ref(null);

      function toolTipChart(context) {
        // Tooltip Element
        let tooltipEl = document.querySelector('.chartjsTooltip');

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.classList.add('chartjsTooltip');
          tooltipEl.innerHTML = '<div class="chartjsTooltip__block"></div>';
          verticalBarChart.value.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        const tooltipModel = context.tooltip;
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);

          let innerHtml = '<span class="chartjsTooltip__block__title">';

          titleLines.forEach(function (title) {
            innerHtml += title;
          });
          innerHtml += '</span><div class="chartjsTooltip__block__infoBlock">';

          bodyLines.forEach(function (body, i) {
            const colors = tooltipModel.labelColors[i];
            let style = 'background:' + colors.backgroundColor;
            style += '; border-color:' + colors.borderColor;
            style += '; border-width: 2px';
            const colorValue = `
          <div class="chartjsTooltip__block__infoBlock__colorBlock">
            <span class="chartjsTooltip__block__infoBlock__colorBlock__color" style="${style}"></span>
            <span class="chartjsTooltip__block__infoBlock__colorBlock__colorValue">${body}</span>
          </div>`;
            innerHtml += colorValue;
            const valueProgress = tooltipModel.dataPoints[i].raw.progress;
            if (valueProgress) {
              const progress = `
            <span class="chartjsTooltip__block__infoBlock__progress ${
              valueProgress > 0 ? 'green' : valueProgress == 0 ? '' : 'red'
            }">
              ${valueProgress > 0 ? '+' + valueProgress : valueProgress}%
            </span>`;
              innerHtml += progress;
            }
          });

          innerHtml += '</div>';

          let tableRoot = tooltipEl.querySelector('.chartjsTooltip__block');
          tableRoot.innerHTML = innerHtml;
        }

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = tooltipModel.caretX + 'px';
        tooltipEl.style.top = tooltipModel.caretY - 50 + 'px';
        tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
        tooltipEl.style.pointerEvents = 'none';
      }

      const options = ref({
        parsing: {
          yAxisKey: 'x',
        },
        locale: 'ru-RU',
        layout: {
          padding: 20,
        },

        maintainAspectRatio: false,
        barThickness: 68,
        maxBarThickness: 60,
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              padding: 28,
              boxWidth: props.pointSize,
              boxHeight: props.pointSize,
              usePointStyle: true,
              useBorderRadius: true,
              borderRadius: 360,
              color: '000',
              font: {
                size: 15,
                family: 'Fira Sans',
                lineHeight: 1,
                weight: '400',
              },
            },
          },
          datalabels: {
            color: '#231F20',
            anchor: 'end',
            align: 'top',
            font: {
              size: 20,
              family: 'Fira Sans',
              lineHeight: 1,
              weight: '400',
            },
            formatter: function (value) {
              return value.x;
            },
          },
          tooltip: {
            enabled: props.tooltip == 'default',
            external: props.tooltip == 'custom' ? toolTipChart : null,
          },
          title: {
            display: true,
            text: props.title,
            align: 'start',
            color: '#A1A6AD',
            font: {
              size: 20,
              family: 'Fira Sans',
              lineHeight: 1,
              weight: '500',
            },
            padding: {
              bottom: 40,
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },

            ticks: {
              color: '#989898',
              font: {
                size: 15,
                family: 'Fira Sans',
                lineHeight: 1,
                weight: '400',
              },
            },
          },
          y: {
            grid: {
              display: true,
              drawBorder: false,
              color: '#E7EFF4',
            },
            ticks: {
              stepSize: props.stepSizeY,
              color: '#989898',
              font: {
                size: 16,
                family: 'Fira Sans',
                lineHeight: 1,
                weight: '400',
              },
            },
          },
        },
      });

      return {
        options,
        verticalBarChart,
      };
    },
  });
</script>

<style lang="scss">
  .verticalBarChart {
    position: relative;

    @media (max-width: 650px) {
      overflow: hidden;
      overflow-x: auto;
    }

    &__chart {
      min-width: 650px;
    }

    &--project-statistics {
      border-radius: 4px;
      background: $light-grey;
      padding: 25px 40px;
    }

    &__no-data {
      position: absolute;
      z-index: 1;
      top: 245px;
      left: 25px;
      width: 100%;
      text-align: center;
      min-width: 650px;
      font: $fira-14;
    }
  }

  .chartjsTooltip {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    border-radius: 8px;

    &__block {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__title {
        color: $white;
        font-family: 'Fira Sans';
        font: $fira-16;
        display: block;
        margin: 0 0 5px 0;
      }

      &__infoBlock {
        display: flex;
        align-items: center;

        &__colorBlock {
          display: flex;
          align-items: center;
          gap: 5px;
          white-space: nowrap;

          &__color {
            width: 12px;
            height: 12px;
            border-radius: 360px;
            min-width: 12px;
            min-height: 12px;
          }

          &__colorValue {
            color: $white;
            font-family: 'Fira Sans';
            font: $fira-16;
          }
        }

        &__progress {
          margin: 0 0 0 10px;
          color: $white;
          font-family: 'Fira Sans';
          font: $fira-16-M;

          &.green {
            color: #28a745;
          }

          &.red {
            color: #f66;
          }
        }
      }
    }
  }
</style>
