<template>
  <div class="table-project">
    <div class="table-project__title">
      Проектов в текущем периоде
      <span>{{ tableInfo.itemsTotal }}</span>
    </div>
    <div class="table-container-base table-container-base--modified">
      <table>
        <thead>
          <tr>
            <th class="column-ps-status">Статус</th>
            <th class="column-ps-name">Название</th>
            <th class="column-ps-country">Страна</th>
            <th class="column-ps-russian-side">Российская сторона</th>
            <th class="column-ps-foreign-party">Иностранная сторона</th>
            <th class="column-ps-start">Начало</th>
            <th class="column-ps-end">Конец</th>
          </tr>
        </thead>
        <tbody v-if="tableInfo.items.length">
          <tr v-for="item in tableInfo.items" :key="item.id" @click="goToPage(item.id)" class="hover-tr">
            <td>
              <span :class="getClassStatus(item.statusId)">{{ item.status.name }}</span>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.country.name }}</td>
            <td>{{ item.mpkComissionName }}</td>
            <td>{{ item.participantsForeign }}</td>
            <td>{{ $momentFormat(item.created, 'MMMM YYYY') }}</td>
            <td>{{ $momentFormat(item.projectStoppedDate, 'MMMM YYYY') }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center" colspan="11">Данных нет</td>
          </tr>
        </tbody>
      </table>
    </div>
    <template v-if="tableInfo.items.pagesTotal > 1">
      <PaginationRedesigned
        :page-count="tableInfo.pagesTotal"
        :current-page="tableInfo.pageNumber"
        @change="onPageChange($event, false)"
        @more="onPageMore($event, true)"
      />
    </template>
  </div>
</template>

<script>
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';

  export default {
    name: 'TableProject',
    components: {
      PaginationRedesigned,
    },
    props: {
      tableInfo: {
        type: Object,
        default: () => {
          return {
            pageNumber: 1,
            pageSize: 10,
            items: [],
            itemsTotal: 0,
            pagesTotal: 1,
          };
        },
      },
    },
    methods: {
      getClassStatus(id) {
        switch (id) {
          case 1:
            return 'green-dark';
          case 2:
            return 'green';
          case 3:
            return 'blue';
          default:
            return 'red';
        }
      },

      goToPage(id) {
        this.$router.push({ name: 'ProjectCard', params: { id } });
      },

      onPageChange(pageNumber) {
        this.$emit('onPageChange', pageNumber);
      },

      onPageMore(pageNumber) {
        this.$emit('onPageMore', pageNumber);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/table/table-base.scss';
  .table-project {
    margin-top: 24px;

    &__title {
      margin-bottom: 24px;
      font: $fira-16-M;
      color: $base;

      span {
        margin-left: 8px;
        font: $fira-16-M;
        background: -webkit-linear-gradient(90deg, #0078c8 0%, #01a39d 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
</style>
