<template>
  <div class="chart-pie-project">
    <div class="chart-pie-project__graph">
      <apexchart type="pie" :options="chartOptions" :series="series" ref="apexchart" />
    </div>
    <div class="chart-pie-project__info">
      <div v-for="item in info" :key="item.id" class="chart-pie-project__item">
        <p class="chart-pie-project__item-count" :style="`color: ${item.color}`">
          <span class="chart-pie-project__item-dots" :style="`background-color: ${item.color}`"></span>
          {{ item.count }}
        </p>
        <p class="chart-pie-project__item-text">{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ChartPieProject',
    props: {
      labelsArray: {
        type: Array,
        default: () => [],
      },
      countArray: {
        type: Array,
        default: () => [],
      },
      color: {
        type: Array,
        default: () => [],
      },
      info: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        chartOptions: {},
        series: {},
      };
    },
    created() {
      this.chartOptions = {
        chart: {
          type: 'pie',
        },
        labels: this.labelsArray,
        colors: this.color,
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '16px',
            fontFamily: 'Fira Sans',
            colors: ['#231F20'],
          },
          background: {
            enabled: false,
          },
          dropShadow: {
            enabled: false,
          },
        },
        noData: {
          text: 'Данных нет',
        },
        plotOptions: {
          pie: {
            // запрет на увеличение области по клику
            expandOnClick: false,
          },
        },
      };

      this.series = this.countArray;
    },
    watch: {
      countArray(val) {
        this.$refs.apexchart.updateSeries(val);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chart-pie-project {
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid $grey;
    background: $light-grey;
    max-width: 848px;
    padding: 36px 0;
    overflow: hidden;

    &__graph {
      width: 424px;

      .vue-apexcharts {
        height: 100%;
        width: 100%;
      }
    }

    &__info {
      margin-left: 64px;
    }

    &__item {
      margin-bottom: 32px;
      padding-left: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      &-count {
        font: $fira-32-M;
        position: relative;
      }

      &-text {
        font: $fira-16;
        color: $base;
      }

      &-dots {
        position: absolute;
        width: 12px;
        height: 12px;
        right: 100%;
        border-radius: 50%;
        top: 14px;
        margin-right: 12px;
      }
    }

    @media (max-width: $tablet) {
      &__info {
        margin-left: -4px;
      }
    }

    @media (max-width: $mobile) {
      flex-wrap: wrap;

      &__info {
        margin-left: 0;
        width: 100%;
        padding: 0 46px;
      }

      &__graph {
        margin: 0 0 50px;
      }

      &__item {
        margin-bottom: 20px;
      }
    }
  }
</style>
