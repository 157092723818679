<template>
  <section class="section-project-statistics">
    <h2 v-if="title" class="title title--h2 title--projects-statistics">{{ title }}</h2>
    <div class="section-project-statistics__wrap-nav">
      <template v-if="type === 'portfolio-change-dynamics'">
        <div class="section-project-statistics__nav-item select">
          <SelectMultiComponentCheckbox
            v-model="item.CountryIds"
            placeholder="Выберите страны"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="optionCheckboxCountriesDuplicate"
            mod="small"
          />
        </div>
        <div class="section-project-statistics__nav-item select">
          <SelectMultiComponentCheckbox
            v-model="item.StatusIds"
            placeholder="Выберите статусы"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="optionCheckboxStatus"
            mod="small"
          />
        </div>
        <div class="section-project-statistics__nav-item">
          <ButtonComponent @click="goToPage('Projects')" mod="gradient-bg">Подробнее</ButtonComponent>
        </div>
      </template>

      <template v-else-if="type === 'dynamics-quantity-status-inclusion-exclusion'">
        <div class="section-project-statistics__nav-item select">
          <SelectMultiComponent
            v-model="item.CountryId"
            :options="optionCountries"
            placeholder="Выберите страну"
            modifier="rectangular"
          />
        </div>
        <div class="section-project-statistics__nav-item select">
          <SelectMultiComponent
            v-model="item.MpkEventMeetingId"
            :options="optionProjectsMPKPortfolio"
            placeholder="Выберите МПК"
            modifier="rectangular"
          />
        </div>
        <div class="section-project-statistics__nav-item select">
          <SelectMultiComponent
            v-model="item.DynamicMode"
            :options="optionProjectMPK"
            placeholder=""
            modifier="rectangular"
          />
        </div>
        <div class="section-project-statistics__nav-item">
          <ButtonComponent @click="goToPage('Projects')" mod="gradient-bg">Подробнее</ButtonComponent>
        </div>
      </template>
      <template v-else-if="type === 'distribution-of-projects'">
        <div class="section-project-statistics__nav-item select">
          <span class="label-field">Страна</span>
          <SelectMultiComponentCheckbox
            v-model="item.CountryIds"
            placeholder="Все"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="optionCheckboxCountriesDuplicate"
            mod="small"
          />
        </div>
        <div class="section-project-statistics__nav-item select">
          <span class="label-field">Статус</span>
          <SelectMultiComponentCheckbox
            v-model="item.StatusIds"
            placeholder="Все"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="optionCheckboxStatus"
            mod="small"
          />
        </div>
        <div class="section-project-statistics__nav-item">
          <ButtonComponent @click="$emit('click-more')" mod="gradient-bg">Подробнее</ButtonComponent>
        </div>
      </template>

      <template v-else-if="type === 'number-jobs-created'">
        <div class="section-project-statistics__nav-item select">
          <SelectMultiComponent
            v-model="item.countryId"
            :options="optionCountries"
            placeholder="Выберите страну"
            modifier="rectangular"
            class="visible-clear"
          />
        </div>
        <div class="section-project-statistics__nav-item">
          <ButtonComponent @click="goToPage('Projects')" mod="gradient-bg">Подробнее</ButtonComponent>
        </div>
      </template>

      <template v-else-if="type === 'share-green-projects'">
        <div class="section-project-statistics__nav-item select">
          <SelectMultiComponent
            v-model="item.countryId"
            :options="optionCountries"
            placeholder="Выберите страну"
            modifier="rectangular"
            class="visible-clear"
          />
        </div>
        <div class="section-project-statistics__nav-item select">
          <SelectMultiComponent
            v-model="item.mpkEventMeetingId"
            :options="optionProjectsMPKPortfolio"
            placeholder="За всё время"
            modifier="rectangular"
            class="visible-clear"
          />
        </div>
        <div class="section-project-statistics__nav-item">
          <ButtonComponent @click="goToPage('Projects')" mod="gradient-bg">Подробнее</ButtonComponent>
        </div>
      </template>
    </div>
    <div
      class="section-project-statistics__wrap-nav"
      v-if="type === 'dynamics-quantity-status-inclusion-exclusion' || type === 'number-jobs-created'"
    >
      <p class="section-project-statistics__nav-item label-field full">Выберите период</p>
      <div class="section-project-statistics__nav-item select">
        <dateYearComponent
          v-model="item.CustomPeriodFrom"
          :clearable="false"
          placeholder="Выберите"
          :dateIcon="true"
          modClass="redesigned small"
          minimumViewDate="year"
          :id="idPeriodFrom"
          dateFormatProps="yyyy"
        />
      </div>
      <span class="section-project-statistics__hyphen"></span>
      <div class="section-project-statistics__nav-item select">
        <dateYearComponent
          v-model="item.CustomPeriodTo"
          :clearable="false"
          placeholder="Выберите"
          :dateIcon="true"
          modClass="redesigned small"
          minimumViewDate="year"
          :id="idPeriodTo"
          dateFormatProps="yyyy"
        />
      </div>
    </div>

    <div class="section-project-statistics__content">
      <slot />
    </div>
  </section>
</template>

<script>
  import dateYearComponent from '@/common/components/dateYearComponent.vue';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import SelectMultiComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectMultiComponentCheckbox.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';

  import constants from '../constants';

  export default {
    name: 'SectionStatistics',
    components: {
      SelectMultiComponentCheckbox,
      ButtonComponent,
      dateYearComponent,
      SelectMultiComponent,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: '',
        validator(value) {
          return [
            'portfolio-change-dynamics',
            'dynamics-quantity-status-inclusion-exclusion',
            'number-jobs-created',
            'share-green-projects',
            'distribution-of-projects',
          ].includes(value);
        },
      },
      optionCheckboxCountries: {
        type: Array,
        default: () => [],
      },
      optionCheckboxStatus: {
        type: Array,
        default: () => [],
      },
      optionCountries: {
        type: Array,
        default: () => [],
      },
      optionProjectsMPKPortfolio: {
        type: Array,
        default: () => [],
      },
      optionProjectMPK: {
        type: Array,
        default: () => [],
      },
      filterMpkData: {
        type: Object,
        default: () => {},
      },
      idPeriodFrom: String,
      idPeriodTo: String,
    },
    data() {
      return {
        optionCheckboxCountriesDuplicate: [],
        item: {
          CountryIds: [],
          StatusIds: [],
          CountryId: '',
          MpkEventMeetingId: '',
          DynamicMode: constants.DEFAULT_DYNAMIC,
          CustomPeriodFrom: '',
          CustomPeriodTo: '',
          countryId: '',
          mpkEventMeetingId: '',
        },
        countryDisabled: false,
      };
    },
    watch: {
      item: {
        handler(val) {
          this.eventChangeData(val);
        },
        deep: true,
      },
      optionProjectsMPKPortfolio: {
        handler() {
          this.item.MpkEventMeetingId = this.optionProjectsMPKPortfolio.length
            ? this.optionProjectsMPKPortfolio[0].value
            : 0;
        },
        deep: true,
      },
      optionCountries: {
        handler() {
          this.item.CountryId = constants.DEFAULT_COUNTRY;
        },
        deep: true,
      },
      optionCheckboxCountries: {
        handler() {
          this.optionCheckboxCountriesDuplicate = JSON.parse(JSON.stringify(this.optionCheckboxCountries));
        },
        deep: true,
      },
      filterMpkData: {
        handler(newVal) {
          this.item.CountryIds = newVal?.countryIds ? newVal.countryIds : [];
          this.item.StatusIds = newVal?.statusIds ? newVal.statusIds : [];
        },
        deep: true,
      },
      'item.CountryIds'(val) {
        this.disableList(val);
      },
    },
    methods: {
      goToPage(name) {
        if (name) {
          this.$router.push({ name });
        }
      },

      eventChangeData(data) {
        const {
          CountryIds = [],
          StatusIds = [],
          CountryId = '',
          MpkEventMeetingId = '',
          DynamicMode = '',
          CustomPeriodFrom = '',
          CustomPeriodTo = '',
          countryId = '',
          mpkEventMeetingId = '',
        } = data;

        if (this.type === constants.TYPE_SECTION_PHD) {
          this.$emit('changeData', {
            CountryIds,
            StatusIds,
          });
        }
        if (this.type === constants.TYPE_SECTION_DQSIE) {
          this.$emit('changeData', {
            CountryId,
            MpkEventMeetingId,
            DynamicMode,
            CustomPeriodFrom,
            CustomPeriodTo,
          });
        }
        if (this.type === constants.TYPE_SECTION_NJC) {
          this.$emit('changeData', {
            countryId,
            CustomPeriodFrom,
            CustomPeriodTo,
          });
        }
        if (this.type === constants.TYPE_SECTION_SGP) {
          this.$emit('changeData', {
            countryId,
            mpkEventMeetingId,
          });
        }
        if (this.type === constants.TYPE_SECTION_DOP) {
          this.$emit('changeData', {
            CountryIds,
            StatusIds,
          });
        }
      },

      disableList(listId) {
        if (this.countryDisabled) {
          this.optionCheckboxCountriesDuplicate = this.optionCheckboxCountriesDuplicate.map((i) => ({
            ...i,
            disabled: false,
          }));
          this.countryDisabled = false;
        }

        if (listId.length >= 10) {
          this.optionCheckboxCountriesDuplicate = this.optionCheckboxCountriesDuplicate.map((i) => ({
            ...i,
            disabled: true,
          }));

          this.optionCheckboxCountriesDuplicate.forEach((i) => {
            listId.forEach((j) => {
              if (+i.id === +j) {
                i.disabled = false;
              }
            });
          });
          this.countryDisabled = true;
        }
      },
    },
  };
</script>

<style lang="scss">
  @import '@/assets/theme/default/title.scss';
  .section-project-statistics {
    margin-bottom: 40px;

    &__wrap {
      &-nav {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px;
      }
    }

    &__nav-item {
      margin: 0 8px 24px;

      &.select {
        width: 308px;
      }
    }

    &__hyphen {
      width: 8px;
      height: 2px;
      display: inline-block;
      background: $base;
      margin: 19px 0 0;
    }

    &.distribution-of-projects {
      & .section-project-statistics__wrap-nav {
        align-items: flex-end;
      }
    }

    .full {
      width: 100%;
      margin-bottom: 8px;
    }

    @media (max-width: $laptop) {
      &__hyphen {
        display: none;
      }
    }

    @media (max-width: $mobile) {
      &__nav-item {
        margin-bottom: 16px;

        &.select {
          width: 100%;
        }
      }
    }
  }
</style>
